/* Estilos generales del navbar */
nav {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 50;
}

nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

nav li {
  padding: 0.8em;
  text-align: center;
  transition: all 0.2s;
}

nav a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
}

nav a:hover {
  color: #ddd;
}

/* Fondo personalizado */
.bg-custom-dark {
  background-color: #06071B; /* Fondo personalizado */
}

/* Fondo del menú desplegable */
.menu.open {
  background-color: #06071B;
  position: absolute;
  top: 5em;
  right: 0;
  width: 200px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 1em;
  z-index: 9;
}

#nav-bg {
  background: #06071B;
  position: fixed;
  height: 5em;
  width: 100%;
  top: 0;
  z-index: 1;
}

.menu-icon {
  display: none;
  font-size: 2em;
  cursor: pointer;
  color: #fff;
}

.menu {
  display: flex;
  justify-content: flex-end;
}

.menu.open {
  display: flex;
  flex-direction: column;
}

.menu li {
  margin: 0.5em 0;
}

.menu li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.1em;
  transition: color 0.3s;
}

.menu li a:hover {
  color: #bbb;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .menu-icon {
    display: block;
  }

  .menu {
    display: none;
  }

  .menu.open {
    display: flex;
  }

  nav ul {
    flex-direction: column;
    align-items: flex-start;
  }

  nav li {
    padding: 0.5em 0;
  }
}
