.home-container {
  height: 100%; /* Ajusta al contenido de la página */
  min-height: 100vh; /* Asegura altura completa en caso de contenido corto */
  background-color: #06071B; /* Color de fondo sólido */
  display: flex;
  flex-direction: column;
}

  
  .home-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Oscurece el fondo para mejor contraste */
    z-index: 1;
  }
  
  .home-content {
    position: relative;
    z-index: 2; /* Asegura que el contenido esté encima de la capa oscura */
    color: white; /* Color del texto */
  }
  